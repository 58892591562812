var synthwave84 = Object.freeze({
  "colors": {
    "activityBar.background": "#171520",
    "activityBar.dropBackground": "#34294f66",
    "activityBar.foreground": "#ffffffCC",
    "activityBarBadge.background": "#f97e72",
    "activityBarBadge.foreground": "#2a2139",
    "badge.background": "#2a2139",
    "badge.foreground": "#ffffff",
    "breadcrumbPicker.background": "#232530",
    "button.background": "#614D85",
    "debugToolBar.background": "#463465",
    "diffEditor.insertedTextBackground": "#0beb9935",
    "diffEditor.removedTextBackground": "#fe445035",
    "dropdown.background": "#232530",
    "dropdown.listBackground": "#2a2139",
    "editor.background": "#262335",
    "editor.findMatchBackground": "#D18616bb",
    "editor.findMatchHighlightBackground": "#D1861655",
    "editor.findRangeHighlightBackground": "#34294f1a",
    "editor.hoverHighlightBackground": "#463564",
    "editor.lineHighlightBorder": "#7059AB66",
    "editor.rangeHighlightBackground": "#49549539",
    "editor.selectionBackground": "#ffffff20",
    "editor.selectionHighlightBackground": "#ffffff20",
    "editor.wordHighlightBackground": "#34294f88",
    "editor.wordHighlightStrongBackground": "#34294f88",
    "editorBracketMatch.background": "#34294f66",
    "editorBracketMatch.border": "#495495",
    "editorCodeLens.foreground": "#ffffff7c",
    "editorCursor.background": "#241b2f",
    "editorCursor.foreground": "#f97e72",
    "editorError.foreground": "#fe4450",
    "editorGroup.border": "#495495",
    "editorGroup.dropBackground": "#4954954a",
    "editorGroupHeader.tabsBackground": "#241b2f",
    "editorGutter.addedBackground": "#206d4bd6",
    "editorGutter.deletedBackground": "#fa2e46a4",
    "editorGutter.modifiedBackground": "#b893ce8f",
    "editorIndentGuide.activeBackground": "#A148AB80",
    "editorIndentGuide.background": "#444251",
    "editorLineNumber.activeForeground": "#ffffffcc",
    "editorLineNumber.foreground": "#ffffff73",
    "editorOverviewRuler.addedForeground": "#09f7a099",
    "editorOverviewRuler.border": "#34294fb3",
    "editorOverviewRuler.deletedForeground": "#fe445099",
    "editorOverviewRuler.errorForeground": "#fe4450dd",
    "editorOverviewRuler.findMatchForeground": "#D1861699",
    "editorOverviewRuler.modifiedForeground": "#b893ce99",
    "editorOverviewRuler.warningForeground": "#72f1b8cc",
    "editorRuler.foreground": "#A148AB80",
    "editorSuggestWidget.highlightForeground": "#f97e72",
    "editorSuggestWidget.selectedBackground": "#ffffff36",
    "editorWarning.foreground": "#72f1b8cc",
    "editorWidget.background": "#171520DC",
    "editorWidget.border": "#ffffff22",
    "editorWidget.resizeBorder": "#ffffff44",
    "errorForeground": "#fe4450",
    "extensionButton.prominentBackground": "#f97e72",
    "extensionButton.prominentHoverBackground": "#ff7edb",
    "focusBorder": "#1f212b",
    "foreground": "#ffffff",
    "gitDecoration.addedResourceForeground": "#72f1b8cc",
    "gitDecoration.deletedResourceForeground": "#fe4450",
    "gitDecoration.ignoredResourceForeground": "#ffffff59",
    "gitDecoration.modifiedResourceForeground": "#b893ceee",
    "gitDecoration.untrackedResourceForeground": "#72f1b8",
    "input.background": "#2a2139",
    "inputOption.activeBorder": "#ff7edb99",
    "inputValidation.errorBackground": "#fe445080",
    "inputValidation.errorBorder": "#fe445000",
    "list.activeSelectionBackground": "#ffffff20",
    "list.activeSelectionForeground": "#ffffff",
    "list.dropBackground": "#34294f66",
    "list.errorForeground": "#fe4450E6",
    "list.focusBackground": "#ffffff20",
    "list.focusForeground": "#ffffff",
    "list.highlightForeground": "#f97e72",
    "list.hoverBackground": "#37294d99",
    "list.hoverForeground": "#ffffff",
    "list.inactiveFocusBackground": "#2a213999",
    "list.inactiveSelectionBackground": "#ffffff20",
    "list.inactiveSelectionForeground": "#ffffff",
    "list.warningForeground": "#72f1b8bb",
    "menu.background": "#463465",
    "minimapGutter.addedBackground": "#09f7a099",
    "minimapGutter.deletedBackground": "#fe4450",
    "minimapGutter.modifiedBackground": "#b893ce",
    "panelTitle.activeBorder": "#f97e72",
    "peekView.border": "#495495",
    "peekViewEditor.background": "#232530",
    "peekViewEditor.matchHighlightBackground": "#D18616bb",
    "peekViewResult.background": "#232530",
    "peekViewResult.matchHighlightBackground": "#D1861655",
    "peekViewResult.selectionBackground": "#2a213980",
    "peekViewTitle.background": "#232530",
    "pickerGroup.foreground": "#f97e72ea",
    "progressBar.background": "#f97e72",
    "scrollbar.shadow": "#2a2139",
    "scrollbarSlider.activeBackground": "#9d8bca20",
    "scrollbarSlider.background": "#9d8bca30",
    "scrollbarSlider.hoverBackground": "#9d8bca50",
    "selection.background": "#ffffff20",
    "sideBar.background": "#241b2f",
    "sideBar.dropBackground": "#34294f4c",
    "sideBar.foreground": "#ffffff99",
    "sideBarSectionHeader.background": "#241b2f",
    "sideBarSectionHeader.foreground": "#ffffffca",
    "statusBar.background": "#241b2f",
    "statusBar.debuggingBackground": "#f97e72",
    "statusBar.debuggingForeground": "#08080f",
    "statusBar.foreground": "#ffffff80",
    "statusBar.noFolderBackground": "#241b2f",
    "statusBarItem.prominentBackground": "#2a2139",
    "statusBarItem.prominentHoverBackground": "#34294f",
    "tab.activeBorder": "#880088",
    "tab.border": "#241b2f00",
    "tab.inactiveBackground": "#262335",
    "terminal.ansiBlue": "#03edf9",
    "terminal.ansiBrightBlue": "#03edf9",
    "terminal.ansiBrightCyan": "#03edf9",
    "terminal.ansiBrightGreen": "#72f1b8",
    "terminal.ansiBrightMagenta": "#ff7edb",
    "terminal.ansiBrightRed": "#fe4450",
    "terminal.ansiBrightYellow": "#fede5d",
    "terminal.ansiCyan": "#03edf9",
    "terminal.ansiGreen": "#72f1b8",
    "terminal.ansiMagenta": "#ff7edb",
    "terminal.ansiRed": "#fe4450",
    "terminal.ansiYellow": "#f3e70f",
    "terminal.foreground": "#ffffff",
    "terminal.selectionBackground": "#ffffff20",
    "terminalCursor.background": "#ffffff",
    "terminalCursor.foreground": "#03edf9",
    "textLink.activeForeground": "#ff7edb",
    "textLink.foreground": "#f97e72",
    "titleBar.activeBackground": "#241b2f",
    "titleBar.inactiveBackground": "#241b2f",
    "walkThrough.embeddedEditorBackground": "#232530",
    "widget.shadow": "#2a2139"
  },
  "displayName": "Synthwave '84",
  "name": "synthwave-84",
  "semanticHighlighting": true,
  "tokenColors": [
    {
      "scope": [
        "comment",
        "string.quoted.docstring.multi.python",
        "string.quoted.docstring.multi.python punctuation.definition.string.begin.python",
        "string.quoted.docstring.multi.python punctuation.definition.string.end.python"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#848bbd"
      }
    },
    {
      "scope": [
        "string.quoted",
        "string.template",
        "punctuation.definition.string"
      ],
      "settings": {
        "foreground": "#ff8b39"
      }
    },
    {
      "scope": "string.template meta.embedded.line",
      "settings": {
        "foreground": "#b6b1b1"
      }
    },
    {
      "scope": [
        "variable",
        "entity.name.variable"
      ],
      "settings": {
        "foreground": "#ff7edb"
      }
    },
    {
      "scope": "variable.language",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#fe4450"
      }
    },
    {
      "scope": "variable.parameter",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": [
        "storage.type",
        "storage.modifier"
      ],
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": "constant",
      "settings": {
        "foreground": "#f97e72"
      }
    },
    {
      "scope": "string.regexp",
      "settings": {
        "foreground": "#f97e72"
      }
    },
    {
      "scope": "constant.numeric",
      "settings": {
        "foreground": "#f97e72"
      }
    },
    {
      "scope": "constant.language",
      "settings": {
        "foreground": "#f97e72"
      }
    },
    {
      "scope": "constant.character.escape",
      "settings": {
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": "entity.name",
      "settings": {
        "foreground": "#fe4450"
      }
    },
    {
      "scope": "entity.name.tag",
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "punctuation.definition.tag"
      ],
      "settings": {
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": "entity.other.attribute-name",
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": "entity.other.attribute-name.html",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#fede5d"
      }
    },
    {
      "scope": [
        "entity.name.type",
        "meta.attribute.class.html"
      ],
      "settings": {
        "foreground": "#fe4450"
      }
    },
    {
      "scope": "entity.other.inherited-class",
      "settings": {
        "foreground": "#D50"
      }
    },
    {
      "scope": [
        "entity.name.function",
        "variable.function"
      ],
      "settings": {
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": [
        "keyword.control.export.js",
        "keyword.control.import.js"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "constant.numeric.decimal.js"
      ],
      "settings": {
        "foreground": "#2EE2FA"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": "keyword.control",
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": "keyword.operator",
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": [
        "keyword.operator.new",
        "keyword.operator.expression",
        "keyword.operator.logical"
      ],
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": "keyword.other.unit",
      "settings": {
        "foreground": "#f97e72"
      }
    },
    {
      "scope": "support",
      "settings": {
        "foreground": "#fe4450"
      }
    },
    {
      "scope": "support.function",
      "settings": {
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": "support.variable",
      "settings": {
        "foreground": "#ff7edb"
      }
    },
    {
      "scope": [
        "meta.object-literal.key",
        "support.type.property-name"
      ],
      "settings": {
        "foreground": "#ff7edb"
      }
    },
    {
      "scope": "punctuation.separator.key-value",
      "settings": {
        "foreground": "#b6b1b1"
      }
    },
    {
      "scope": "punctuation.section.embedded",
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": [
        "punctuation.definition.template-expression.begin",
        "punctuation.definition.template-expression.end"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "support.type.property-name.css",
        "support.type.property-name.json"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": "switch-block.expr.js",
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": "variable.other.constant.property.js, variable.other.property.js",
      "settings": {
        "foreground": "#2ee2fa"
      }
    },
    {
      "scope": "constant.other.color",
      "settings": {
        "foreground": "#f97e72"
      }
    },
    {
      "scope": "support.constant.font-name",
      "settings": {
        "foreground": "#f97e72"
      }
    },
    {
      "scope": "entity.other.attribute-name.id",
      "settings": {
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": [
        "entity.other.attribute-name.pseudo-element",
        "entity.other.attribute-name.pseudo-class"
      ],
      "settings": {
        "foreground": "#D50"
      }
    },
    {
      "scope": "support.function.misc.css",
      "settings": {
        "foreground": "#fe4450"
      }
    },
    {
      "scope": [
        "markup.heading",
        "entity.name.section"
      ],
      "settings": {
        "foreground": "#ff7edb"
      }
    },
    {
      "scope": [
        "text.html",
        "keyword.operator.assignment"
      ],
      "settings": {
        "foreground": "#ffffffee"
      }
    },
    {
      "scope": "markup.quote",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#b6b1b1cc"
      }
    },
    {
      "scope": "beginning.punctuation.definition.list",
      "settings": {
        "foreground": "#ff7edb"
      }
    },
    {
      "scope": "markup.underline.link",
      "settings": {
        "foreground": "#D50"
      }
    },
    {
      "scope": "string.other.link.description",
      "settings": {
        "foreground": "#f97e72"
      }
    },
    {
      "scope": "meta.function-call.generic.python",
      "settings": {
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": "variable.parameter.function-call.python",
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": "storage.type.cs",
      "settings": {
        "foreground": "#fe4450"
      }
    },
    {
      "scope": "entity.name.variable.local.cs",
      "settings": {
        "foreground": "#ff7edb"
      }
    },
    {
      "scope": [
        "entity.name.variable.field.cs",
        "entity.name.variable.property.cs"
      ],
      "settings": {
        "foreground": "#ff7edb"
      }
    },
    {
      "scope": "constant.other.placeholder.c",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "keyword.control.directive.include.c",
        "keyword.control.directive.define.c"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": "storage.modifier.c",
      "settings": {
        "foreground": "#fe4450"
      }
    },
    {
      "scope": "source.cpp keyword.operator",
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": "constant.other.placeholder.cpp",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "keyword.control.directive.include.cpp",
        "keyword.control.directive.define.cpp"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": "storage.modifier.specifier.const.cpp",
      "settings": {
        "foreground": "#fe4450"
      }
    },
    {
      "scope": [
        "source.elixir support.type.elixir",
        "source.elixir meta.module.elixir entity.name.class.elixir"
      ],
      "settings": {
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": "source.elixir entity.name.function",
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "source.elixir constant.other.symbol.elixir",
        "source.elixir constant.other.keywords.elixir"
      ],
      "settings": {
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": "source.elixir punctuation.definition.string",
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "source.elixir variable.other.readwrite.module.elixir",
        "source.elixir variable.other.readwrite.module.elixir punctuation.definition.variable.elixir"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": "source.elixir .punctuation.binary.elixir",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ff7edb"
      }
    },
    {
      "scope": [
        "entity.global.clojure"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": [
        "storage.control.clojure"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": [
        "meta.metadata.simple.clojure",
        "meta.metadata.map.clojure"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#fe4450"
      }
    },
    {
      "scope": [
        "meta.quoted-expression.clojure"
      ],
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": [
        "meta.symbol.clojure"
      ],
      "settings": {
        "foreground": "#ff7edbff"
      }
    },
    {
      "scope": "source.go",
      "settings": {
        "foreground": "#ff7edbff"
      }
    },
    {
      "scope": "source.go meta.function-call.go",
      "settings": {
        "foreground": "#36f9f6"
      }
    },
    {
      "scope": [
        "source.go keyword.package.go",
        "source.go keyword.import.go",
        "source.go keyword.function.go",
        "source.go keyword.type.go",
        "source.go keyword.const.go",
        "source.go keyword.var.go",
        "source.go keyword.map.go",
        "source.go keyword.channel.go",
        "source.go keyword.control.go"
      ],
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": [
        "source.go storage.type",
        "source.go keyword.struct.go",
        "source.go keyword.interface.go"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "source.go constant.language.go",
        "source.go constant.other.placeholder.go",
        "source.go variable"
      ],
      "settings": {
        "foreground": "#2EE2FA"
      }
    },
    {
      "scope": [
        "markup.underline.link.markdown",
        "markup.inline.raw.string.markdown"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "string.other.link.title.markdown"
      ],
      "settings": {
        "foreground": "#fede5d"
      }
    },
    {
      "scope": [
        "markup.heading.markdown",
        "entity.name.section.markdown"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#ff7edb"
      }
    },
    {
      "scope": [
        "markup.italic.markdown"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#2EE2FA"
      }
    },
    {
      "scope": [
        "markup.bold.markdown"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#2EE2FA"
      }
    },
    {
      "scope": [
        "punctuation.definition.quote.begin.markdown",
        "markup.quote.markdown"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "source.dart",
        "source.python",
        "source.scala"
      ],
      "settings": {
        "foreground": "#ff7edbff"
      }
    },
    {
      "scope": [
        "string.interpolated.single.dart"
      ],
      "settings": {
        "foreground": "#f97e72"
      }
    },
    {
      "scope": [
        "variable.parameter.dart"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    },
    {
      "scope": [
        "constant.numeric.dart"
      ],
      "settings": {
        "foreground": "#2EE2FA"
      }
    },
    {
      "scope": [
        "variable.parameter.scala"
      ],
      "settings": {
        "foreground": "#2EE2FA"
      }
    },
    {
      "scope": [
        "meta.template.expression.scala"
      ],
      "settings": {
        "foreground": "#72f1b8"
      }
    }
  ],
  "type": "dark"
});

export { synthwave84 as default };
